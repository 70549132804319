@import "../core/colors/styles";
@import "../core/mediaQueries/styles";
@import "../core/typography/styles";

@mixin vertical-border($padding, $border-color) {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: $padding;
    bottom: $padding;
    right: 0;
    border-right: 1px solid $border-color;
  }
}

$default-cell-padding: 0.5rem 0.5rem;

.table-scroll-loading{
  & .scroll-content{
    height: 100% !important;
  }
}

.ts-table-container {
  height: calc(100vh - var(--page-table-top-offset, 220px) - var(--page-table-bottom-offset, 0px));
  display: flex;
  flex-direction: column;
}

.ts-table-container-short {
  &.ts-table-container {
    max-height: 430px;
    overflow: hidden;

    @include breakpoint-2xl {
      max-height: 550px;
    }
  }

  & .ts-table-container {
    max-height: 430px;
    overflow: hidden;
    @include breakpoint-2xl {
      max-height: 550px;
    }
  }
}

.ts-table {
  width: fit-content;
  min-width: 100%;

  &__header {
    padding: 0.5rem;
    background-color: $surface-secondary-color;
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 20;
    border-bottom: 1px solid $separator-primary-color;
    border-top: 1px solid $separator-primary-color;

    & .cell {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      line-height: 1.5rem;

    }

    & .cell:not(:last-child) {
      @include vertical-border(0, $separator-primary-color);
    }

  }

  &__row {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: max-content;
    background-color: $table-neutral-color;
    display: flex;
    align-items: center;
    color: $text-primary-color;
    @include border-style('bottom');
    cursor: pointer;

    &.not-selectable{
      cursor: default;
    }

    & .cell:not(:last-child) {
      @include vertical-border(0.25rem, $separator-secondary-color);
    }

    &.disabled {
      color: $text-disabled-color;
    }

    & .cell {
      display: flex;
      align-self: stretch;
      align-items: center;
      position: relative;
      flex: 0 0 250px;
      width: 250px;

      &:has(.cell-error) {
        background-color: $surface-error-color;
      }

      @include ts-caption-strong;

      & .ts-content {
        padding: $default-cell-padding;
        white-space: nowrap;
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
      }
    }

    & .table-editable-cell {
      width: 100%;
      padding: 0.5rem 0.25rem;
      border-radius: 0.5rem;
    }

    & .table-editable-cell-placeholder{
      width: 100%;
      @include ts-caption-strong;
      color: $text-placeholder-color;
      padding: 0.5rem 0.25rem;
      border-radius: 0.5rem;
    }

    &:hover, &.hovered {
      background-color: $table-hover-color;

      & .table-editable-cell {
        background-color: $surface-primary-color;
      }

      & .table-editable-cell-placeholder{
        background-color: $surface-primary-color;
      }
    }

    &.selected {
      background-color: $table-selected-color;
    }

    &.visited {
      background-color: $table-visited-color;
    }
  }
}

.ts-current-row {
  background-color: $table-visited-color;
}

.ts-table__no-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: $text-placeholder-color;
  flex-grow: 1;

  max-width: 100dvw;
  position: fixed;
  top:400px;

  & .ts-icon {
    font-size: 2rem;
  }
}

.cell-editable {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  padding: 0;
}

.cell-input {
  background: $surface-primary-color;
  outline: none;
  width: 100%;
  display: block;
  align-self: stretch;
  border: 1px solid transparent;
  border-radius: 6px;
  padding: 6px;
  color: $text-primary-color;

  &:focus {
    @include border-style('all', $separator-primary-accent-color);
  }

  &::placeholder {
    color: $text-placeholder-color;
  }
}

.ts-table-fixed-row {
  & .ts-table__row {
    height: 48px;
  }
}

.table-summary-row{
  @include border-style('top');
  overflow-x: auto;
  overflow-y: hidden;
}

.container-pagination {
    .ts-table-container {
      --page-table-bottom-offset: 46px;
    }

}

.annotation-container {
  background: $table-update-color;
  color: $text-info-color;
  @include ts-caption-strong;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;

  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.annotation-container-error {
  background: $surface-error-color;
  color: $text-error-color;
  @include ts-caption-strong;
  border-radius: 0.5rem;
  padding: 6px;

  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: max-content;
}

.ts-table-header-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__label {
    @include ts-subtitle-small;
    color: $text-primary-color;
    line-height: unset;
  }
}
